import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import FindUsView from '../components/FindUsView';

import '../styles/global.scss';

const StoreLocatorPage = () => (
  <Layout view="find-us">
    <Seo title="Find Us" />
    <FindUsView />
  </Layout>
);

export default StoreLocatorPage;
