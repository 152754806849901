import React from 'react';
import { TweenMax, Linear } from 'gsap/TweenMax';

import Text from '../Text';
import Image from '../Image';
import { TEXT_TYPE } from '../../types/textTypes';
import { isMobile } from '../../utils/media';

import './FindUsView.scss';

class FindUsView extends React.PureComponent {
  onMoodSelect = (mood, transitionDuration = 3) => {
    const gradients = {
      chill:
        'linear-gradient(to top, #e31c79, #f557a1 39%, #f74397 62%, #e31c79)',
      zzzz:
        'linear-gradient(to top, #981d97, #ce1ca9 39%, #c438c3 62%, #981d97)',
      fresh:
        'linear-gradient(to top, #f4364c, #ff3f69 39%, #ff3f69 62%, #f4364c)',
      lively:
        'linear-gradient(to top, #ff6534, #ff9323 34%, #ff8200 46%, #ff6534)',
      dialed:
        'linear-gradient(to top, #ffae00, #ffd83a 34%, #ffcd00 71%, #ffbc00)',
    };
    TweenMax.to(this.moodRef.current, transitionDuration, {
      backgroundImage: gradients[mood],
      ease: Linear.ease,
    });
  };

  render() {
    return (
      <article className="FindUsView" ref={this.moodRef}>
        {isMobile() ? (
          <>
            <Text
              size={TEXT_TYPE.h3}
              tag="div"
              color="white"
              value="Find Select near you."
              style={{
                alignSelf: 'center',
                marginBottom: 40,
              }}
            />
          </>
        ) : (
          <Text
            size={TEXT_TYPE.h3}
            tag="div"
            color="white"
            value="Find Select near you."
            style={{
              alignSelf: 'center',
              marginTop: 5,
              marginBottom: 40,
            }}
          />
        )}
        <iframe src="https://hoodie-app.vercel.app/embed/brandstores/615b9087a83d59da9eeb5f73" title="Select Product Finder" allow="geolocation" />
      </article>
    );
  }
}

export default FindUsView;
