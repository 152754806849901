/*
 * isMobile
 * Returns if the browser's window width is for movile view
 * @note also this should be covered iPhoneXR landscape mode (width: 896px)
 * @return {boolean}
 */
export const isMobile = () => (
  window.innerWidth < 768
  // (window.innerWidth <= 896 && window.innerWidth > window.innerHeight) // mobile horizontal
);

export const isTablet = () => window.innerWidth >= 768 && window.innerWidth <= 1024;
/*
 * isDesktop
 * Returns if the browser's window width is for desktop view
 * @return {boolean}
 */
export const isDesktop = () => window.innerWidth >= 1280;

/*
 * isTouchDevice
 * Returns if device has touch event
 * @return {boolean}
 */
export const isTouchDevice = () => 'ontouchstart' in window;
